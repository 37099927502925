// utils/API.js

import axios from "axios"
import apiConfig from './apiConfig'

const apiConfiguration = { ...apiConfig }

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REACT_API_BASE_URL_CONFIG,
  timeout: 5000,
  headers: { 'x-api-key': process.env.NEXT_PUBLIC_API_KEY, }
})
const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled
}

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    request.headers['access'] = 'hello'
  }
  // ** Get token from localStorage
  const accessToken = localStorage.getItem(apiConfiguration.storageTokenKeyName)

  // ** If token is present add it to request's Authorization Header
  if (accessToken) {
    // ** eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `${apiConfiguration.tokenType} ${accessToken}`
  }
  return request
}

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    console.log('inter =>', error)
  }
  return Promise.reject({ ...error })
}

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }
  return response
}


axiosInstance.interceptors.request.use(
  request => requestHandler(request),
  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)


export { axiosInstance }