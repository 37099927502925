import { axiosInstance } from '../api'
import apiConfig from '../apiConfig'

export default class MiscService {

    apiConfigration = { ...apiConfig }

    constructor() { }

    subscribe(form) {
        return axiosInstance({
            method: 'post',
            url: "/api/v1/misc/subscribe",
            data: form
        }, {
            handlerEnabled: true
        }).then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                //console.log(error.response.data)
                // error.response.data.map((err) => {
                //     alert('ERROR')
                //     //notifyToast(err.detail, 'danger', true)
                // })
            } else if (error.request) {
                // client never received a response, or request never left
                //notifyToast(error.request, 'danger', true)
                alert('ERROR')
            } else {
                // anything else
                //notifyToast('Please Contact Administrator', 'danger', true)
                alert('ERROR')
            }
            return Promise.reject(error)
            // handle the error here...
        })
    }

    inquiry(form) {
        return axiosInstance({
            method: 'post',
            url: "/api/v1/misc/inquiry",
            data: form
        }, {
            handlerEnabled: true
        }).then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                //console.log(error.response.data)
                // error.response.data.map((err) => {
                //     alert('ERROR')
                //     //notifyToast(err.detail, 'danger', true)
                // })
            } else if (error.request) {
                // client never received a response, or request never left
                //notifyToast(error.request, 'danger', true)
                alert('ERROR')
            } else {
                // anything else
                //notifyToast('Please Contact Administrator', 'danger', true)
                alert('ERROR')
            }
            return Promise.reject(error)
            // handle the error here...
        })
    }

    contactUs(form) {
        return axiosInstance({
            method: 'post',
            url: "/api/v1/misc/contact-us",
            data: form
        }, {
            handlerEnabled: true
        }).then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                //console.log(error.response.data)
                // error.response.data.map((err) => {
                //     alert('ERROR')
                //     //notifyToast(err.detail, 'danger', true)
                // })
            } else if (error.request) {
                // client never received a response, or request never left
                //notifyToast(error.request, 'danger', true)
                alert('ERROR')
            } else {
                // anything else
                //notifyToast('Please Contact Administrator', 'danger', true)
                alert('ERROR')
            }
            return Promise.reject(error)
            // handle the error here...
        })
    }

    careerPost(form) {
        var body = new FormData();
        body.append('resume', form.resume);
        body.append('firstName', form.firstName);
        body.append('lastName', form.lastName);
        body.append('email', form.email);
        body.append('message', form.message);

        return axiosInstance({
            method: 'post',
            url: "/api/v1/misc/career",
            data: body,
            headers: { "Content-Type": "multipart/form-data" },
        }, {
            handlerEnabled: true
        }).then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            if (error.response) {
                // client received an error response (5xx, 4xx)
                //console.log(error.response.data)
                // error.response.data.map((err) => {
                //     alert('ERROR')
                //     //notifyToast(err.detail, 'danger', true)
                // })
            } else if (error.request) {
                // client never received a response, or request never left
                //notifyToast(error.request, 'danger', true)
                alert('ERROR')
            } else {
                // anything else
                //notifyToast('Please Contact Administrator', 'danger', true)
                alert('ERROR')
            }
            return Promise.reject(error)
            // handle the error here...
        })
    }
}