import React, { Fragment, useEffect } from "react";
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import MiscService from '../../../services/misc/miscService';
import { Formik } from "formik"
import * as Yup from 'yup';

import { toast } from 'react-toastify';

const Footer = () => {
  const miscService = new MiscService()

  useEffect(() => { }, []);

  function handleSubscribe(values, actions) {
    miscService.subscribe(values)
      .then(response => {
        toast("Successfully Subscribe...!");
        actions.resetForm();
      })
  }

  const validateSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });

  return (
    <Fragment>
      <footer className="app2 bg footer2 p-0">
        <div className="subscribe subscribe-2">
          <Container>
            <Row>
              <Col lg="8" md="10" className="offset-lg-2 offset-md-1">
                <Formik
                  enableReinitialize={true}
                  initialValues={{ email: "" }}
                  validationSchema={validateSchema}
                  onSubmit={(values, actions) => handleSubscribe(values, actions)}
                >
                  {props => (
                    <form onSubmit={props.handleSubmit}>
                      <div className="subscribe">
                        <div className="center-content">
                          <FormGroup className="m-0">
                            <div className="flex">
                              <i aria-hidden="true" className="fa fa-envelope icon"></i>
                              <Input
                                className="form-control text-capitalize"
                                name="email"
                                placeholder="example@domain.com"
                                type="email"
                                id="email"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.email}
                                required
                              />
                              <div className="button-primary">
                                <button
                                  className="btn btn-default btn-gradient text-white text-capitalize"
                                  type="submit"
                                >
                                  subscribe now
                                </button>
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                    </form>
                  )}

                </Formik>

              </Col>
            </Row>
          </Container>
        </div>
        <section>
          <Container>
            <Row>
              <Col lg="3" md="6" sm="12">
                <div className="logo-sec">
                  <div className="footer-title mobile-title p-t-0">
                    <h3 className="text-white">About Us</h3>
                  </div>
                  <div className="footer-contant">
                    <h5 className="footer-headings">About us</h5>
                    <div className="footer-para">
                      <p className="text-white">
                        We offer various services of complete web development,
                        software development, portfolios, desktop based
                        application, wedding portfolios and mobile application
                        development.
                      </p>
                    </div>
                    <ul className="d-flex footer-social social">
                      <li className="footer-social-list">
                        <a href="#">
                          <i aria-hidden="true" className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="footer-social-list">
                        <a href="#">
                          <i aria-hidden="true" className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="footer-social-list">
                        <a href="#">
                          <i aria-hidden="true" className="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="4" md="6" sm="12">
                <div className="footer-title mobile-title">
                  <h3 className="text-white">Our Technology</h3>
                </div>
                <div className="footer-contant">
                  <h5 className="footer-headings">Our Technology</h5>
                  <div className="link-btns">
                    <ul>
                      <li className="buttons">
                        <a href="#"> Design</a>
                      </li>
                      <li className="buttons">
                        <a href="#">Website</a>
                      </li>
                      <li className="buttons">
                        <a href="#">Native App </a>
                      </li>
                      <li className="buttons">
                        <a href="#">Software</a>
                      </li>
                      <li className="buttons">
                        <a href="#">Maintenance</a>
                      </li>
                      <li className="buttons">
                        <a href="#">Mobile App</a>
                      </li>
                      <li className="buttons">
                        <a href="#">SEO</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>

              <Col lg="4" md="6" sm="12">
                <div className="footer-title mobile-title">
                  <h3 className="text-white">Contact Info</h3>
                </div>
                <div className="footer-contant">
                  <h5 className="footer-headings">Contact Info</h5>
                  <div>
                    <ul className="footer-lists contacts">
                      <li>
                        <i aria-hidden="true" className="fa fa-map-marker"></i>
                        Pune : Mumbai-Pune ByPass, Bavdhan - 411021 <br />
                        Chandrapur : Gurudwara Road, Tukum- 442401
                      </li>
                      {/* <li>
                      <i aria-hidden="true" className="fa fa-phone m-r-15"></i>
                      +91 8600221672
                    </li> */}
                      <li>
                        <i
                          aria-hidden="true"
                          className="fa fa-envelope m-r-15"
                        ></i>
                        <span>contactus@nidik.com</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </footer>
      <div className="copyright inner-page">
        <Container>
          <Row>
            <Col sm="6">
              <div className="link-horizontal">
                <ul>
                  <li>
                    <a className="copyright-text" href="/misc/privacy-policy">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="copyright-text" href="/misc/terms-and-conditions">
                      Terms &amp; Conditions
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm="6">
              <div>
                <h6 className="copyright-text text-white text-right">
                  Copyright © 2024 | NIDIK WEBCON. All Rights Reserved.
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default Footer;
