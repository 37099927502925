// ** Auth Endpoints
export default {
    tokenType: 'Bearer',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',

    //Form Config Api's
    formConfigEndpoint: '/v1/form/',

    //Media Config
    mediaConfigEndpoint: '/v1/media/library/nodes', 
    mediaConfigFileEndpoint: '/v1/media/library/node/',

    //Navigations & routers
    configMenuEndpoint:'/v1/navigations',
    configRouterEndpoint:'/v1/routers'


  }  