export const MENUITEMS = [
  {
    title: "About Us",
    path: "/about/us",
    type: "sub",
    children: [
      { path: "/about/us", title: "About us", type: "link" },
      { path: "/about/who-we-are", title: "Who we are", type: "link" },
    ],
  },
  {
    title: "Product & Solution",
    type: "sub",
    path: "/product-and-solution",
    children: [
      {
        path: "/product-and-solution/billing-software",
        title: "Billing Software",
        type: "link",
      },
      {
        path: "/product-and-solution/hotel-management-system",
        title: "Hotel Management System",
        type: "link",
      },
      {
        path: "/product-and-solution/inventory-management-system",
        title: "Inventory Management System",
        type: "link",
      },
      {
        path: "/product-and-solution/payroll-management-system",
        title: "Payroll Management Software",
        type: "link",
      },
      {
        path: "/product-and-solution/hospital-management-system",
        title: "Hospital Management System",
        type: "link",
      },
      {
        path: "/product-and-solution/college-management-system",
        title: "School/College Management System",
        type: "link",
      },
      {
        path: "/product-and-solution/retail-management-system",
        title: "Retail Management System",
        type: "link",
      },
    ],
  },
  {
    title: "Services",
    type: "sub",
    path: "/services",
    children: [
      {
        title: "Business Services",
        type: "sub",
        path: "/services/business-services",
        children: [
          {
            path: "/services/business-services/product-engineering",
            title: "Product Engineering",
            type: "link",
          },
          {
            path: "/services/business-services/application-integration",
            title: "Application Integration",
            type: "link",
          },
          {
            path: "/services/business-services/website-desgin-and-development",
            title: "Website Desgin and Development",
            type: "link",
          },
          {
            path: "/services/business-services/application-development",
            title: "Application Development",
            type: "link",
          },
          // {
          //   path: "/services/business-services/product-engineering",
          //   title: "Application Maintancance",
          //   type: "link",
          // },
          {
            path: "/services/business-services/mobile-application-development",
            title: "Mobile Application Development",
            type: "link",
          },
          // { path: "/services/", title: "IT Consulting", type: "link" },
          {
            path: "/services/business-services/quality-assurance-and-testing",
            title: "Quality Assurance and Testing",
            type: "link",
          },
        ],
      },
      {
        title: "Digital Services",
        type: "sub",
        path: "/services/digital-services",
        children: [
          {
            path: "/services/digital-services/digital-marketing",
            title: "Digital Marketing",
            type: "link",
          },
          {
            path: "/services/digital-services/internet-of-things",
            title: "Internet Of Things",
            type: "link",
          },
          {
            path: "/services/digital-services/data-analytics",
            title: "Data Analytics",
            type: "link",
          },
          {
            path: "/services/digital-services/mobility",
            title: "Mobility",
            type: "link",
          },
          {
            path: "/services/digital-services/ui-ux-design",
            title: "UI / UX Design",
            type: "link",
          },
          // {
          //   path: "/services/digital-services/product-engineering",
          //   title: "eCommerce",
          //   type: "link",
          // },
          {
            path: "/services/digital-services/cloud-computing",
            title: "Cloud Computing",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    title: "Industries",
    type: "sub",
    path: "/industries",
    children: [
      {
        path: "/industries/retail",
        title: "Retail",
        type: "link",
      },
      {
        path: "/industries/healthcare",
        title: "Healthcare",
        type: "link",
      },
      {
        path: "/industries/education",
        title: "Education",
        type: "link",
      },
      {
        path: "/industries/public-sector",
        title: "Public Sector",
        type: "link",
      },
      {
        path: "/industries/banking",
        title: "Banking",
        type: "link",
      },
      {
        path: "/industries/finance",
        title: "Finance",
        type: "link",
      },
      // {
      //   path: "/industries/insurance",
      //   title: "Insurance",
      //   type: "link",
      // },
    ],
  },
  {
    title: "NIDIK Insight",
    type: "sub",
    path: "/insight",
    children: [
      // {
      //   path: "/insight/news-feeds",
      //   title: "News Feeds",
      //   type: "link",
      // },
      // {
      //   path: "/insight/blogs",
      //   title: "Blogs",
      //   type: "link",
      // },
      // {
      //   path: "/insight/case-studies",
      //   title: "Case Study",
      //   type: "link",
      // },
      {
        path: "/insight/career",
        title: "Career",
        type: "link",
      },
    ],
  },
];
